import React from "react"
import {Link} from "gatsby";
import Layout from "components/layout"
import SEO from "components/seo"
import OutSideLink from "../components/outlink";
import {allContext} from "utils/context-util";


const TagValidator = () => {


	const sortedAllContext = allContext.map(ctx => {

		const postIdsInLinkMap = Array.from(new Set(Object.values(ctx.linkMap)));
		const postIdsInStories = ctx.stories.map(el => el.postId).filter(el => el !== '')
		const storyMap = {};
		ctx.stories.forEach(el => {
			storyMap[el.postId] = el.title
		});

		const notExistTags4PostIds = postIdsInStories.filter(el => !postIdsInLinkMap.includes(el));
		return {ctx, notExistTags4PostIds, postIdsInLinkMap, storyMap, postIdsInStories};
	}).sort((a, b) => {
		const aLenght = a.notExistTags4PostIds.length;
		const bLenght = b.notExistTags4PostIds.length;
		if (aLenght > bLenght) {
			return 1;
		}
		if (aLenght < bLenght) {
			return -1;
		}
		// a must be equal to b
		return 0;
	})

	const storyCountDom = sortedAllContext.map(({
		                                            ctx,
		                                            notExistTags4PostIds,
		                                            postIdsInLinkMap,
		                                            storyMap,
		                                            postIdsInStories
	                                            }, index) => {
		const notExistTags4PostIdsDOM = notExistTags4PostIds.map(el => <div>{storyMap[el]} has no tags</div>)
		const extraTagsNotIncludesStories = postIdsInLinkMap.filter(el => !postIdsInStories.includes(el));
		const extraTagsNotIncludesStoriesDOM = extraTagsNotIncludesStories.map(el =>
			<OutSideLink title={`${el} extra tags`} url={`https://medium.com/@odayibasi/${el}`}/>)


		return (
			<div>
				<h4>{index}.<Link to={ctx.path}>{ctx.title}</Link> has {ctx.stories.length} stories;</h4>
				{notExistTags4PostIdsDOM}
				{extraTagsNotIncludesStoriesDOM}
			</div>
		)
	})


	return (
		<Layout isVisibleBack={true}>
			<SEO title="Tag Validator"/>
			<div>
				<h2>Tag Validator</h2>
				{storyCountDom}
			</div>


		</Layout>
	)

}

export default TagValidator



